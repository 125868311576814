import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';

import Visit from 'src/components/pages/visit';
import VisitVc from 'src/components/pages/visitVc';
import UnsupportedBrowser from 'src/components/pages/unsupportedBrowser';
import UnsupportedIosBrowser from 'src/components/pages/unsupportedIosBrowser';
import { isUnsupportedBrowser, isUnsupportedIosBrowser } from 'src/util';

function getContents(routeName) {
  if (isUnsupportedBrowser()) {
    return <UnsupportedBrowser />;
  }

  if (isUnsupportedIosBrowser()) {
    return <UnsupportedIosBrowser />;
  }

  switch (routeName) {
    case 'video':
      return <VisitVc />;
    case 'unsupportedBrowser':
      return <UnsupportedBrowser />;
    default:
      return <Visit />;
  }
}

const Main = ({ classes, rootStore: { routerStore } }) => (
  <div>
    <Modal open={!!routerStore.isTransitioning} className={classes.progressContainer}>
      <CircularProgress className={classes.progress} />
    </Modal>

    {getContents(routerStore.routerState.routeName)}
  </div>
);

const styles = () => ({
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    width: 100,
    height: 100,
    outline: 'none',
  },
});

export default withStyles(styles)(inject('rootStore')(observer(Main)));
