import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { SUPPORT_NUMBER } from 'src/util/constants';

const WelcomeMask = ({
  children,
  classes,
  headerClassName,
  headerText = 'Welcome to Boulder Care!',
  headerIcon,
}) => (
  <div className={classes.maskContainer}>
    <img className={classes.boulderIcon} src="/logotype_white.png" alt="Boulder" />
    <div className={classes.welcomeContents}>
      {headerIcon}
      {headerText && (
        <h1 className={classnames(classes.welcomeMessage, headerClassName)}>{headerText}</h1>
      )}
      {children}
    </div>
    <div className={classes.welcomeFooter}>
      <strong>Questions?</strong> Give us a call at{' '}
      <a className={classes.phoneLink} href={`tel:${SUPPORT_NUMBER.raw}`}>
        {SUPPORT_NUMBER.label}
      </a>
      .
    </div>
  </div>
);

const styles = () => ({
  maskContainer: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100vw',
    position: 'absolute',
    backgroundColor: '#4b4b4b',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  boulderIcon: {
    maxWidth: 150,
    padding: 20,
  },
  phoneLink: {
    color: 'inherit',
    '&:hover': {
      color: '#90C7A4',
    },
  },
  welcomeContents: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: '0 20px',
    fontFamily: '"Roboto", sans-serif',
    textAlign: 'center',
  },
  welcomeMessage: {
    fontSize: 20,
    '@media (min-width: 768px)': {
      fontSize: 36,
    },
  },
  welcomeFooter: {
    fontSize: 14,
    fontStyle: 'italic',
    color: 'white',
    marginBottom: 20,
    textAlign: 'center',
    width: '100%',

    '@media (min-width: 768px)': {
      fontSize: 16,
    },
  },
});

export default withStyles(styles)(WelcomeMask);
