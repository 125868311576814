import { types, flow, getEnv } from 'mobx-state-tree';
import { createRouterState, RouterStore } from 'mobx-state-router';
import gql from 'graphql-tag';

import logger from 'src/util/logger';
import routes from '../boot/routes';

const notFound = createRouterState('notFound');

const VERIFY_EVENT_VISIT_CODE = gql`
  mutation($code: String!) {
    staff_verifyEventVisitCode(code: $code)
  }
`;

// Reference to a promise we can resolve to abort an ongoing upload
export default types
  .model('RootStore', {
    sessionId: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
  })
  .volatile(self => {
    const routerStore = new RouterStore(routes, notFound, { rootStore: self });

    return {
      routerStore,
    };
  })
  .actions(self => ({
    /**
     * Given a visit code, verify if it's valid and return the guest URL for
     * the corresponding event video if so.
     */
    verifyEventVisitCode: flow(function* verifyEventVisitCode(code) {
      if (!code) {
        return '';
      }
      try {
        const results = yield getEnv(self).apolloClient.mutate({
          mutation: VERIFY_EVENT_VISIT_CODE,
          variables: { code },
        });
        return results.data.staff_verifyEventVisitCode;
      } catch (e) {
        logger.warn(e.message);
        throw e;
      }
    }),
  }));
