import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import WelcomeMask from 'src/components/pages/pageElements/welcomeMask';

const UnsupportedIosBrowser = ({ classes }) => {
  return (
    <WelcomeMask
      headerIcon={<ErrorOutlineIcon className={classes.errorIcon} />}
      headerClassName={classes.errorMessage}
      headerText="Unsupported Web Browser"
    >
      <div className={classes.instructions}>
        Unfortunately, this web browser is not supported by Boulder Care&apos;s video visit system.
        We recommend loading this page in Safari, and trying again.
      </div>
    </WelcomeMask>
  );
};

const styles = () => ({
  errorIcon: {
    color: '#FF6666',
    fontSize: 60,
  },
  errorMessage: {
    color: '#FF6666',
  },
  instructions: {
    fontSize: 18,
    maxWidth: 400,
    padding: '0 35px',
  },
});

export default withStyles(styles)(UnsupportedIosBrowser);
