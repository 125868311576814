import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import 'opentok-solutions-css';
import classNames from 'classnames';

const VideoConferenceControls = ({
  showCameraSwitchButton,
  onSwitchCamera,
  localAudioEnabled,
  onToggleLocalAudio,
  localVideoEnabled,
  onToggleLocalVideo,
  onEndCall,
  classes,
}) => (
  <div className={classes.controls}>
    {showCameraSwitchButton && (
      <Fab className={classes.cameraSwitchButton} onClick={onSwitchCamera}>
        <SwitchCameraIcon />
      </Fab>
    )}
    <div
      className={classNames('ots-video-control circle audio', {
        muted: !localAudioEnabled,
      })}
      onClick={onToggleLocalAudio}
    />
    <div
      className={classNames('ots-video-control circle video', {
        muted: !localVideoEnabled,
      })}
      onClick={onToggleLocalVideo}
    />
    <div className={classNames('ots-video-control circle end-call')} onClick={onEndCall} />
  </div>
);

const styles = () => ({
  cameraSwitchButton: {
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    margin: '20px 5px',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
  '@global': {
    '.ots-video-control': {
      width: 50,
      height: 50,
      margin: '20px 5px !important',
      borderRadius: '50%',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      transition: 'transform .2s',
    },
    '.ots-video-control:hover': {
      transform: 'scale(1.1)',
    },
    '.ots-video-control.audio, .ots-video-control.audio:hover': {
      backgroundImage: 'url(https://assets.tokbox.com/solutions/images/icon-mic.png)',
    },
    '.ots-video-control.audio.muted': {
      backgroundColor: 'rgba(255, 255, 255, .4)',
    },
    '.ots-video-control.video, .ots-video-control.video:hover': {
      backgroundImage: 'url(https://assets.tokbox.com/solutions/images/icon-video.png)',
    },
    '.ots-video-control.video.muted': {
      backgroundColor: 'rgba(255, 255, 255, .4)',
    },
    '.ots-video-control.end-call': {
      backgroundImage: 'url(https://assets.tokbox.com/solutions/images/icon-hang-up.png)',
      backgroundColor: 'red',
    },
  },
});

export default withStyles(styles)(VideoConferenceControls);
