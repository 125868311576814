export default [
  {
    name: 'homepage',
    pattern: '/',
  },
  {
    name: 'video',
    pattern: '/video',
  },
  {
    name: 'notFound',
    pattern: '/not-found',
  },
  {
    name: 'unsupportedBrowser',
    pattern: '/unsupportedBrowser',
  },
];
