export const apiHost = process.env.REACT_APP_BOULDER_API_HOST;
export const apiSecure = process.env.REACT_APP_BOULDER_API_SECURE || false;
export const apiUrl = `http${apiSecure ? 's' : ''}://${apiHost}`;

export const OPENTOK_API_KEY = process.env.REACT_APP_OPENTOK_API_KEY;

// IE detection code source:
// https://www.w3docs.com/snippets/javascript/how-to-detect-internet-explorer-in-javascript.html
export function isUnsupportedBrowser() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // Other browsers (latest versions) should technically be supported
  return false;
}

export function isUnsupportedIosBrowser() {
  const ua = window.navigator.userAgent;
  const iOS = /(iPad|iPhone|iPod)/gi.test(ua);
  const isSafari = !/(CriOS|FxiOS|OPiOS|mercury)/gi.test(ua);
  return iOS && !isSafari;
}
