import uuid from 'uuid/v4';
import { getCookie } from 'src/util/cookie';

export const getDeviceId = () => {
  const oldDeviceId = getCookie('device-id');

  if (!oldDeviceId) {
    const newDeviceId = uuid(); // Note that this is NOT an HTTPOnly cookie - if we start using this ID for authentication
    // or identification in any way, may want to rethink how this is set to prevent unauthorized
    // access.

    document.cookie = `device-id=${newDeviceId}`;
    return newDeviceId;
  }

  return oldDeviceId;
};
