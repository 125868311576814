import React from 'react';
import { inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ReactCodeInput from 'react-code-input';

import WelcomeMask from 'src/components/pages/pageElements/welcomeMask';

class Visit extends React.Component {
  state = {
    code: '',
    isValid: true,
  };

  verifyVisitCode = async code => {
    try {
      const guestUrl = await this.props.rootStore.verifyEventVisitCode(code);
      window.open(guestUrl, '_self');
    } catch {
      this.setState({ isValid: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { code, isValid } = this.state;

    return (
      <WelcomeMask>
        <p>To join a video visit, enter your Visit ID:</p>
        <ReactCodeInput
          className={classes.codeInputContainer}
          inputStyle={{
            backgroundColor: '#666666',
            border: 'none',
            color: 'white',
            fontSize: 48,
            outline: isValid ? 'none' : `2px solid #EB5757`,
            textAlign: 'center',
            padding: 1,
          }}
          type="number"
          fields={6}
          value={code}
          onChange={val => this.setState({ code: val, isValid: true })}
        />
        <p className={classes.invalidWarning}>{!isValid && 'Please try again.'}</p>
        <Button
          disabled={!isValid || code.length < 6}
          onClick={() => this.verifyVisitCode(code)}
          variant="contained"
          color="primary"
          className={classes.joinButton}
        >
          Join
        </Button>
      </WelcomeMask>
    );
  }
}

const codeStylesByDevice = {
  mobile: {
    height: 50,
    width: 40,
    marginRight: 10,
  },
  tablet: {
    height: 80,
    width: 70,
    marginRight: 20,
  },
  desktop: {
    height: 100,
    width: 100,
    marginRight: 32,
  },
};

const styles = () => ({
  codeInputContainer: {
    '@media (max-width: 767px)': {
      '& input': {
        ...codeStylesByDevice.mobile,

        '&:nth-of-type(6)': {
          marginRight: 0,
        },
      },
    },

    '@media (min-width: 768px) and (max-width: 1024px)': {
      marginTop: 30,
      marginBottom: 30,

      '& input': {
        ...codeStylesByDevice.tablet,

        '&:nth-of-type(1), &:nth-of-type(4)': {
          marginLeft: codeStylesByDevice.tablet.marginRight,
        },
      },
    },

    '@media (min-width: 1025px)': {
      marginTop: 30,
      marginBottom: 30,

      '& input': {
        ...codeStylesByDevice.desktop,

        '&:nth-of-type(1), &:nth-of-type(4)': {
          marginLeft: codeStylesByDevice.desktop.marginRight,
        },
      },
    },

    '& input:focus': {
      outline: 'white solid 2px',
    },

    // Remove arrows/spinners from number fields in chrome, safari, edge, opera
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    // Same for Firefox
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  invalidWarning: {
    color: '#EB5757',
    fontSize: 28,
    fontStyle: 'italic',
    // Keeps the paragraph tag from collapsing when there's no error message shown,
    // so the JOIN button isn't relocated on error state
    height: 28,
  },
  joinButton: {
    '@media (min-width: 768px)': {
      marginTop: 30,
    },
  },
});

export default withStyles(styles)(inject('rootStore')(Visit));
