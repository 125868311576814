import React from 'react';
import { inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Div100vh from 'react-div-100vh';

import { SUPPORT_NUMBER } from 'src/util/constants';
import logger from 'src/util/logger';
import WelcomeMask from 'src/components/pages/pageElements/welcomeMask';
import VideoConferenceControls from 'src/components/pages/pageElements/videoConferenceControls';
import VideoConferenceViewer from 'src/components/pages/pageElements/videoConferenceViewer';

const errorMasks = {
  ViewerError: (
    <p>
      This link is not valid, or has expired.
      <br />
      Please ask for a new link.
    </p>
  ),
  DeviceAccessError: (
    <p>
      Unable to access your camera and microphone. Please adjust your browser&apos;s privacy
      settings.
    </p>
  ),
};

const StartCallMask = ({ onStart, className }) => (
  <div className={className}>
    <WelcomeMask>
      <Button variant="contained" color="primary" onClick={onStart}>
        Join
      </Button>
    </WelcomeMask>
  </div>
);

const ShowErrorMask = ({ error, className }) => {
  let errorMask = errorMasks[error.name];
  if (!errorMask) {
    errorMask = (
      <p>
        An unexpected error occurred. Please try again, or give us a call at{' '}
        <a href={`tel:${SUPPORT_NUMBER.raw}`}>{SUPPORT_NUMBER.label}</a>.
      </p>
    );
  }

  return (
    <div className={className}>
      <WelcomeMask>{errorMask}</WelcomeMask>
    </div>
  );
};

/**
 * Count the number of video input devices that we can successfully get a video stream for.
 *
 * @returns {number|undefined} The number of video devices available, or undefined if devices could not be enumerated.
 */
async function getVideoDeviceCount() {
  if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const hasMediaChecks = devices
      .filter(device => device.kind === 'videoinput')
      .map(device =>
        navigator.mediaDevices
          .getUserMedia({ video: { deviceId: { exact: device.deviceId } } })
          .catch(() => false),
      );

    const hasMediaResults = await Promise.all(hasMediaChecks);
    hasMediaResults.forEach(stream => {
      if (stream) {
        try {
          stream.getTracks().forEach(track => track.stop());
        } catch (err) {
          console.log(err);
        }
      }
    });
    return hasMediaResults.filter(hasMedia => hasMedia).length;
  }
  return undefined;
}

/**
 * Returns whether or not we'll be able to reliably access the camera and microphone
 *
 * Things that can cause this to return false:
 *  1. Permissions for either the camera or mic have not been granted.
 *  2. The device lacks a camera or microphone.
 *  3. The page was loaded in an insecure context.
 *  4. The browser is _very_ old and the necessary APIs are not available.
 *
 * @returns {boolean} Whether or not the camera & mic are available.
 */
async function canAccessDevices() {
  if (!navigator.mediaDevices) {
    // Either this is a _very_ old browser, or the page is not secure
    // See: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Privacy_and_security
    logger.error('navigator.mediaDevices is unavailable. The page may be loading insecurely.');

    return false;
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    stream.getTracks().forEach(track => track.stop());

    return true;
  } catch (err) {
    logger.error(`Unable to get A/V stream: ${err.message}`);
    return false;
  }
}

class VideoConference extends React.Component {
  viewerRef = React.createRef();

  state = {
    active: false,
    devicesAvailable: false,
    localAudioEnabled: true,
    localVideoEnabled: true,
    showCameraSwitchButton: false,
    switchCamera: null,
  };

  async componentDidMount() {
    // Part of checking for device access is that the browser will prompt
    // for permission for both audio and video at the same time, which is
    // a nice side-effect, because otherwise our subsequent code here would
    // prompt for video and then audio separately when the user joins the call
    if (!(await canAccessDevices())) {
      this.setState({
        error: new DeviceAccessError(),
      });
      return;
    }

    this.setState({
      devicesAvailable: true,
    });

    const videoDeviceCount = await getVideoDeviceCount();
    this.setState({
      showCameraSwitchButton: videoDeviceCount !== undefined && videoDeviceCount > 1,
    });

    // TODO call OT.checkSystemRequirements and redirect to /unsupportedBrowser
  }

  startCall = async () => {
    this.setState({ active: true });
  };

  endCall = () => {
    this.setState({ active: false });
  };

  toggleLocalAudio = () => {
    this.setState(oldState => ({
      localAudioEnabled: !oldState.localAudioEnabled,
    }));
  };

  toggleLocalVideo = () => {
    this.setState(oldState => ({
      localVideoEnabled: !oldState.localVideoEnabled,
    }));
  };

  switchCamera = () => {
    if (this.state.switchCamera) {
      this.state.switchCamera();
    }
  };

  setSwitchCamera = switchCamera => this.setState({ switchCamera });

  onViewerError = err => {
    this.setState({ error: new ViewerError() });
    logger.error('Failed to connect: ', err.message);
  };

  renderContents() {
    const { classes, sessionId, token } = this.props;
    const {
      active,
      devicesAvailable,
      error,
      localAudioEnabled,
      localVideoEnabled,
      showCameraSwitchButton,
    } = this.state;

    if (error) {
      return <ShowErrorMask error={error} className={classes.mask} />;
    }

    if (devicesAvailable) {
      if (!active) {
        return <StartCallMask onStart={this.startCall} className={classes.mask} />;
      } else {
        return (
          <>
            <VideoConferenceViewer
              localAudioEnabled={localAudioEnabled}
              localVideoEnabled={localVideoEnabled}
              onError={this.onViewerError}
              sessionId={sessionId}
              setSwitchCamera={this.setSwitchCamera}
              token={token}
            />
            <VideoConferenceControls
              showCameraSwitchButton={showCameraSwitchButton}
              onSwitchCamera={this.switchCamera}
              localAudioEnabled={localAudioEnabled}
              onToggleLocalAudio={this.toggleLocalAudio}
              localVideoEnabled={localVideoEnabled}
              onToggleLocalVideo={this.toggleLocalVideo}
              onEndCall={this.endCall}
            />
          </>
        );
      }
    }

    logger.error("Devices aren't available, but an error also wasn't registered.");
    return <ShowErrorMask error={new DeviceAccessError()} className={classes.mask} />;
  }

  render() {
    const { classes } = this.props;
    return <Div100vh className={classes.main}>{this.renderContents()}</Div100vh>;
  }
}

const styles = () => ({
  main: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: 9999,
    top: 0,
    backgroundColor: 'black',
  },
  mask: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'relative',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

class ViewerError extends Error {
  constructor() {
    super();
    this.name = 'ViewerError';
  }
}

class DeviceAccessError extends Error {
  constructor() {
    super();
    this.name = 'DeviceAccessError';
  }
}

export default withStyles(styles)(inject('rootStore')(VideoConference));
