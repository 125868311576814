import React from 'react';
import { inject } from 'mobx-react';
import VideoConference from 'src/components/pages/pageElements/videoConference';

const VisitVc = ({
  rootStore: {
    routerStore: {
      routerState: {
        queryParams: { session, token },
      },
    },
  },
}) => (
  <>
    {session && token && (
      <VideoConference
        sessionId={session}
        token={token}
        enableArchiving={false}
        showWelcomeMessage
      />
    )}
  </>
);

export default inject('rootStore')(VisitVc);
