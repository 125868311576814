import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import stringify from 'json-stringify-safe';
import schema from 'src/generated/schema.json';

// TODO For some reason, if you import this directly from the app, you get a different instance so
// when the upload link does instanceof ReactNativeFile, it returns false and doesn't do the upload.
// As a hack workaround, re-export it here and then use this version instead of importing directly.
export { ReactNativeFile } from 'apollo-upload-client';
export { ApolloError } from 'apollo-client';

function getContentType(headers) {
  const maybeHeaderMap = headers?.map;
  if (maybeHeaderMap) {
    return maybeHeaderMap['content-type'];
  }
  return undefined;
}

export function graphQLErrorLogMessages(graphQLErrors, operationName) {
  return graphQLErrors.map(
    ({ message, locations, path }) =>
      `[GraphQL error]: Operation: ${operationName}, Message: ${message}, Location: ${stringify(
        locations,
      )}, Path: ${path}`,
  );
}

export function networkErrorMessage(networkError) {
  if (!networkError.response) {
    return `[Network error]: No response: ${networkError}`;
  }

  const { status, headers } = networkError.response;
  const jsonType = /application\/json/;
  const contentType = getContentType(headers);
  if (contentType && jsonType.test(contentType)) {
    return `[Network error]: ${networkError}`;
  } else {
    return `[Network error]: Status ${status}, content-type: ${contentType}`;
  }
}

function defaultOnError({ operation, graphQLErrors, networkError }) {
  if (graphQLErrors) {
    const messages = graphQLErrorLogMessages(graphQLErrors, operation.name);
    messages.forEach(msg => console.log(msg));
  }
  if (networkError) {
    console.log(networkErrorMessage(networkError));
  }
}

/**
 * Create a link to add headers to the graphql request, with
 * the value given by a function that will be called on each
 * request.
 *
 * @param {headerName: headerValueFn} headerFns
 */
function createHeaderLink(headerFns) {
  return setContext((_, { headers }) => {
    const newHeaders = Object.entries(headerFns).map(([header, headerFn]) => {
      const val = headerFn?.();
      return val ? { [header]: val } : null;
    });

    return {
      headers: Object.assign({}, headers, ...newHeaders),
    };
  });
}

/**
 * Initialize an instance of the Apollo Client pointing to the API server. We can't use the simple Boost
 * version because we want to support subscriptions via websockets, so instead this is copied and pasted
 * from https://www.apollographql.com/docs/react/advanced/boost-migration.html.
 *
 * apiHost: the hostname of the boulder api, can also include a port
 * apiSecure: boolean indicating whether to use https or http
 * includeCookies (boolean): should we include cookies in the request? In the staff app we use cookies
 *   for auth so pass true, but in the client app we don't use cookies (see boulder-server/src/auth for
 *   an explanation)
 * getAuthToken (fn): callback function that returns a string representing the user's current auth token,
 *   used by the patient app to set the Authorization header.
 */
export function createApolloClient(
  apiHost,
  apiSecure,
  includeCookies,
  getAuthToken,
  getTraceId,
  getDeviceId,
  getSessionId,
  getUserId,
  onErrorCB = defaultOnError,
) {
  const headerLink = createHeaderLink({
    Authorization: () => {
      // Add the Authorization header if getAuthToken exists and returns a result
      const token = getAuthToken && getAuthToken();
      return token ? `Bearer ${token}` : null;
    },
    'x-request-id': getTraceId,
    'x-device-id': getDeviceId,
    'x-user-id': getUserId,
    'x-session-id': getSessionId,
  });

  // Fetch doesn't support upload progress or upload cancellation, so we're using
  // a solution suggested here:
  // https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261
  const parseHeaders = rawHeaders => {
    const headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(line => {
      const parts = line.split(':');
      const key = parts.shift().trim();
      if (key) {
        const value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers;
  };

  const uploadFetch = (url, options) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const opts = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() ?? ''),
        };
        opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
        const body = 'response' in xhr ? xhr.response : xhr.responseText;
        resolve(new Response(body, opts));
      };
      xhr.onerror = () => {
        reject(new TypeError('Network request failed'));
      };
      xhr.ontimeout = () => {
        reject(new TypeError('Network request failed'));
      };
      xhr.open(options.method, url, true);

      Object.keys(options.headers).forEach(key => {
        xhr.setRequestHeader(key, options.headers[key]);
      });

      if (xhr.upload) {
        xhr.upload.onprogress = options.onUploadProgress;
      }

      if (options.onAbortPromise) {
        options.onAbortPromise.then(() => xhr?.abort?.());
      }

      xhr.send(options.body);
    });

  const httpLink = createUploadLink({
    uri: `http${apiSecure ? 's' : ''}://${apiHost}`,
    fetch: (uri, options) => {
      if (options.useUpload) {
        return uploadFetch(uri, options);
      }
      return fetch(uri, options);
    },
  });

  const splitLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    new WebSocketLink({
      uri: `ws${apiSecure ? 's' : ''}://${apiHost}`, // TODO for prod
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: () => ({
          auth: getAuthToken && getAuthToken(),
          traceId: getTraceId && getTraceId(),
          deviceId: getDeviceId && getDeviceId(),
          sessionId: getSessionId && getSessionId(),
          userId: getUserId && getUserId(),
        }),
      },
    }),
    httpLink,
  );

  const client = new ApolloClient({
    link: ApolloLink.from([onError(onErrorCB), headerLink, splitLink]),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: schema,
      }),
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  return client;
}
