import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { HistoryAdapter } from 'mobx-state-router';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import uuid from 'uuid/v4';

import { createApolloClient } from 'src/client/apolloClient';
import { getDeviceId } from 'src/util/device';

import './App.css';
import logger from 'src/util/logger';
import RootStore from './stores/root';
import { apiHost, apiSecure } from './util';
import Main from './components/app/main';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#60a87d',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const apolloClient = createApolloClient(
  apiHost,
  apiSecure,
  false,
  () => 'external-guest',
  uuid,
  getDeviceId,
  // Use the user ID as the pseudo user ID, since the provider user ID
  // doesn't have PHI concerns
  () => 'external-guest',
);
const rootStore = RootStore.create({}, { apolloClient });
const history = createBrowserHistory();
const historyAdapter = new HistoryAdapter(rootStore.routerStore, history);
historyAdapter.observeRouterStateChanges();

logger.setClient(apolloClient, 'visit');

const App = () => (
  <Provider rootStore={rootStore}>
    <MuiThemeProvider theme={theme}>
      <Main />
    </MuiThemeProvider>
  </Provider>
);

export default App;
